export const AppRoutesBase = '/app';

export const RoutesPathsWithoutBase = {
  Home: '/',
  SignIn: '/signin',
  SignUp: '/signup',
  SignOut: '/signout',
  AuthSuccess: '/auth-success',
  AuthError: '/auth-error',
  AuthEmbed: '/auth-embed',
  EmailVerified: '/email-verified',
  Subscribe: '/subscribe',
  PaymentSuccess: '/payment-success',
  PaymentCanceled: '/payment-canceled',
};

type RouteKey = keyof typeof RoutesPathsWithoutBase;

export const RoutesPaths = Object.entries(RoutesPathsWithoutBase).reduce(
  (acc, [key, value]) => {
    acc[key as RouteKey] = AppRoutesBase + value;
    return acc;
  },
  {} as Record<RouteKey, string>,
);
