import { createContext, useContext, JSX, onMount, Show, createEffect } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import localforage from 'localforage';

export enum Theme {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

type ThemeState = {
  initialized: boolean;
  theme: Theme;
  isSystemPreferred: boolean;
};

type ThemeContextType = {
  theme: () => Theme;
  isSystemPreferred: () => boolean;
  toggleTheme: (value?: Theme) => void;
};

const ThemeContext = createContext<ThemeContextType>();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
};

const getSystemPreferredTheme = (): Theme => {
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.Dark : Theme.Light;
};

const getThemeForWebEnv = async (): Promise<Theme | null> => {
  const url = new URL(window.location.href);
  const urlTheme = url.searchParams.get('theme') as Theme | null;

  if (urlTheme && Object.values(Theme).includes(urlTheme)) {
    url.searchParams.delete('theme');
    window.history.replaceState(null, '', url.toString());
    return urlTheme;
  }
  return (await localforage.getItem('theme')) as Theme | null;
};

const getInitialState = async (webEnv?: boolean): Promise<Omit<ThemeState, 'initialized'>> => {
  const theme = webEnv
    ? await getThemeForWebEnv()
    : ((await localforage.getItem('theme')) as Theme | null);

  const isSystemPreferred = !theme || theme === Theme.System;
  const resolvedTheme = theme && theme !== Theme.System ? theme : getSystemPreferredTheme();

  return {
    theme: resolvedTheme,
    isSystemPreferred,
  };
};

export const ThemeContextProvider: (props: {
  children: JSX.Element;
  webEnv?: boolean;
}) => JSX.Element = (props) => {
  const [state, setState] = createStore<ThemeState>({
    initialized: false,
    theme: Theme.System,
    isSystemPreferred: true,
  });

  onMount(async () => {
    const initialState = await getInitialState(props.webEnv);
    setState(
      produce((state) => {
        state.initialized = true;
        state.theme = initialState.theme;
        state.isSystemPreferred = initialState.isSystemPreferred;
      }),
    );
  });

  const toggleTheme = (value?: Theme) => {
    const currentTheme = state.theme;
    const currentSystemPreferred = state.isSystemPreferred;
    let newTheme = currentTheme;
    let newSystemPreferred = currentSystemPreferred;

    if (value === Theme.System) {
      newTheme = getSystemPreferredTheme();
      newSystemPreferred = true;
    } else if (value) {
      newTheme = value;
      newSystemPreferred = false;
    } else {
      newTheme = currentTheme === Theme.Dark ? Theme.Light : Theme.Dark;
      newSystemPreferred = false;
    }

    if (newTheme !== currentTheme || newSystemPreferred !== currentSystemPreferred) {
      setState(
        produce((state) => {
          state.theme = newTheme;
          state.isSystemPreferred = newSystemPreferred;
        }),
      );

      localforage.setItem('theme', value ?? newTheme);
    }

    return newTheme;
  };

  createEffect(() => {
    document.documentElement.setAttribute('data-theme', state.theme);
  });

  return (
    <Show when={state.initialized}>
      <ThemeContext.Provider
        value={{
          theme: () => state.theme,
          isSystemPreferred: () => state.isSystemPreferred,
          toggleTheme,
        }}
      >
        {props.children}
      </ThemeContext.Provider>
    </Show>
  );
};
