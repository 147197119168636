/* @refresh reload */
import { render } from 'solid-js/web';

import 'ui/src/styles';

import { Navigate, Route, Router, useLocation, useNavigate } from '@solidjs/router';
import { AppRoutesBase, RoutesPaths, RoutesPathsWithoutBase } from './pages/routes';
import { For, lazy, onMount } from 'solid-js';
import { RoutePath as EditorRoutePath, WebBasePath } from 'editor/src/Router';
import { ThemeContextProvider } from 'ui/src/context/Theme';

declare module 'solid-js' {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface Directives {
      form: unknown;
      sortable: unknown;
    }
  }
}

const AppContainer = lazy(() => import('./pages/AppContainer'));
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const AuthSuccess = lazy(() => import('./pages/AuthSuccess'));
const AuthError = lazy(() => import('./pages/AuthError'));
const AuthEmbed = lazy(() => import('./pages/AuthEmbed'));
const EmailVerified = lazy(() => import('./pages/EmailVerified'));
const Subscribe = lazy(() => import('./pages/Subscribe'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const PaymentCanceled = lazy(() => import('./pages/PaymentCanceled'));

const EditorContainer = lazy(() => import('./pages/Editor'));
const EditorMain = lazy(() => import('editor/src/pages/Main'));
const ProjectEditor = lazy(() => import('editor/src/pages/ProjectEditor'));

render(
  () => (
    <ThemeContextProvider webEnv>
      <Router>
        <Route path="/" component={AppContainer}>
          <Route path={RoutesPaths.SignIn} component={SignIn} />
          <Route path={RoutesPaths.SignUp} component={SignUp} />
          <Route path={RoutesPaths.AuthSuccess} component={AuthSuccess} />
          <Route path={RoutesPaths.AuthError} component={AuthError} />
          <Route path={RoutesPaths.AuthEmbed} component={AuthEmbed} />
          <Route path={RoutesPaths.EmailVerified} component={EmailVerified} />
          <Route path={RoutesPaths.Subscribe} component={Subscribe} />
          <Route path={RoutesPaths.PaymentSuccess} component={PaymentSuccess} />
          <Route path={RoutesPaths.PaymentCanceled} component={PaymentCanceled} />

          <For each={Object.keys(RoutesPaths)}>
            {(key) => (
              <Route
                path={RoutesPathsWithoutBase[key as keyof typeof RoutesPaths]}
                component={() => {
                  const location = useLocation();
                  const navigate = useNavigate();
                  onMount(() => {
                    navigate(AppRoutesBase + location.pathname + location.search);
                  });
                  return <></>;
                }}
              />
            )}
          </For>
        </Route>

        <Route path={WebBasePath} component={EditorContainer}>
          <Route path={EditorRoutePath.Main} component={EditorMain} />
          <Route path={EditorRoutePath.Editor} component={ProjectEditor} />
        </Route>

        <Route path="*" component={() => <Navigate href={WebBasePath} />} />
      </Router>
    </ThemeContextProvider>
  ),
  document.getElementById('root')!,
);
